import CheckedSvg from '../../../assets/images/checked.svg';
import SummaryFilterSvg from '../../../assets/images/summary-filter.svg';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { GetQueryParams } from '../../../helpers/getUrlParams';

export const GovernmentSummaryStep = () =>{
    const { t } = useTranslation();
    let { domainId } = useParams();
    let queryPrams = GetQueryParams();

    return(
        <nav aria-label="breadcrumb">
            <ol className="d-flex breadcrumb col-xl-12 mx-auto">
                <li><a href="/#" className="disabled"><span className="circle"><img src={CheckedSvg} width="30px" alt=''/></span>1. {t('ProfileText')}</a></li>
                <li><a href="/#" className="disabled"><span className="circle"><img src={CheckedSvg} width="30px" alt=''/></span>2. {t('DocumentText')}</a></li>
                <li><a href="/#" className="disabled"><span className="circle"><img src={CheckedSvg} width="30px" alt=''/></span>3. {t('DomainInformationText')}</a></li>
                <li className="active"><a href="/#" className="disabled"><span className="circle"><img src={SummaryFilterSvg} width="30px" alt=''/></span>4. {t('SummaryText')}</a></li>
            </ol>
        </nav> 

    )
}