import { EventType, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { lazy, Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { b2cPolicies } from './authConfig';

import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { getCurrentLanguage } from './helpers/getLanguage';
import { useMsalAccessToken } from './hooks/useMsalAccessToken';
import UserProfileService from './services/user/UserProfileService';

import { MyDomain } from 'containers/my_domain/Index';
import { DomainDetailIndex } from 'containers/my_domain_detail/Index';
import { PaymentIndex } from 'containers/payment/Index';
import { SearchDomainIndex } from 'containers/search_domain/Index';
import { UserProfile } from 'containers/user/UserProfile';
import { WhoIs } from 'containers/whois/Index';
import { WhoIsSearchDomain } from 'containers/whois/WhoIsSearchDomain';
import Layout from './v2/core/components/layout/layout';
// import { Invoice } from 'containers/invoice/Invoice';
import { MyCart } from 'containers/my_cart/Index';
import { UpdateServerInfo } from 'containers/my_domain/UpdateServerInfo';
import { PurchaseThank } from 'containers/payment/PurchaseThank';
import { RenewThank } from 'containers/payment/RenewThank';
import { DocumentForm } from 'containers/purchase/DocumentForm';
import { BuyDomainIndex } from 'containers/purchase/Profile';
import { ServerInfoForm } from 'containers/purchase/ServerInfo';
import { PurchaseSuccess } from 'containers/purchase/Success';
import { SummaryForm } from 'containers/purchase/Summary';
import { RenewDocument } from 'containers/resubmit/Document';
import { RenewProfileIndex } from 'containers/resubmit/Profile';
import { RenewSummary } from 'containers/resubmit/Summary';
import { PageBadRequest } from 'components/error_pages/PageBadRequest';
import { PageServerError } from 'components/error_pages/PageServerError';
import { PageNotFound } from 'components/error_pages/PageNotFound';
import { GovernmentProfile } from 'containers/government/Profile';
import { GovernmentDocument } from 'containers/government/Document';
import { GovernmentServerInfo } from 'containers/government/ServerInfo';
import { GovernmentSummary } from 'containers/government/Summary';
import { GovernmentResubmitProfile } from 'containers/government_resubmit/Profile';
import { GovernmentResubmitDocument } from 'containers/government_resubmit/Document';
import { GovernmentResubmitSummary } from 'containers/government_resubmit/Summary';
import { GovernmentDomainDetailIndex } from 'containers/government_domain_detail/Index';
import { TermAndCondition } from 'containers/help/TermCondition';
import { Invoice } from 'containers/invoice/Invoice';

const Home = lazy(() => import('./v2/pages/home/home'));
const HowToRegisterPage = lazy(() => import('v2/pages/how-to-register/how-to-register'));
const FAQsPage = lazy(() => import('v2/pages/faqs/faqs'));
const RequireDocuments = lazy(() => import('v2/pages/require-documents/require-documents'));
/* const SearchDomainPage = lazy(() => import('v2/pages/search-domain/search-domain'));
const MyCartV2 = lazy(() => import('v2/pages/my-cart/my-cart'));
const MyProfilePage = lazy(() => import('v2/pages/my-profile/my-profile')); */
const GeneralInfo = lazy(() => import('v2/pages/general-info/general-info'));
const About = lazy(() => import('v2/pages/about/about'));
const Receipt = lazy(() => import('v2/pages/receipt/receipt'));

const Routers = () => {
    const { i18n } = useTranslation();
    const { instance } = useMsal();

    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    let accessToken = useMsalAccessToken();

    useEffect(() => {
        if (isAuthenticated && accessToken != null) {
            let userProfileService = new UserProfileService();
            userProfileService.checkProfile(accessToken).then((res) => {
                if (!res.data.data) {
                    navigate('/my-profile');
                }
            });
        }
    }, [isAuthenticated, accessToken, navigate]);

    useEffect(() => {
        i18n.changeLanguage(getCurrentLanguage());
    }, [i18n]);

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
                    const forgotPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        extraQueryParameters: {
                            ui_locales: getCurrentLanguage()
                        }
                    };
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(forgotPasswordRequest);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(forgotPasswordRequest).catch((e) => {
                            return;
                        });
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    /* return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search-domain" element={<SearchDomain />} />
      NOT USE <Route path="/whois" element={<WhoIs />} />
      NOT USE <Route path="/whois/search-domain" element={<WhoIsSearchDomain />} />
      <Route path="/whois" element={<WhoIs2 />} />
      <Route path="/whois/search-domain" element={<WhoIs2SearchDomain />} />
      <Route path="/my-domain" element={<MyDomain />} />
      <Route path="/my-domain-detail/:domainId" element={<DomainDetailIndex />} />
      <Route path="/payment/:domainId/:year/:domainName" element={<PaymentIndex />} />
      <Route path="/payment/invoice/:invoiceId" element={<Invoice />} />
      <Route path="/my-cart" element={<MyCart />} />
      <Route path="/my-profile" element={<UserProfile />} />
      <Route path="/update-server-info/:domainId" element={<UpdateServerInfo />} />
      <Route path="/purchase">
        <Route path="profile">
          <Route index element={<BuyDomainIndex />} />
          <Route path=":domainId" element={<BuyDomainIndex />} />
        </Route>
        <Route path="document/:domainId" element={<DocumentForm />} />
        <Route path="server-info/:domainId" element={<ServerInfoForm />} />
        <Route path="summary/:domainId" element={<SummaryForm />} />
      </Route>

      <Route path="/government">
        <Route path="profile">
          <Route index element={<GovernmentProfile />} />
          <Route path=":domainId" element={<GovernmentProfile />} />
        </Route>
        <Route path="document/:domainId" element={<GovernmentDocument />} />
        <Route path="server-info/:domainId" element={<GovernmentServerInfo />} />
        <Route path="summary/:domainId" element={<GovernmentSummary />} />
      </Route>

      <Route path="/government-resubmit">
        <Route path="profile">
          <Route index element={<GovernmentResubmitProfile />} />
          <Route path=":domainId" element={<GovernmentResubmitProfile />} />
        </Route>
        <Route path="document/:domainId" element={<GovernmentResubmitDocument />} />
        <Route path="summary/:domainId" element={<GovernmentResubmitSummary />} />
      </Route>

      <Route path="/government-domain-detail/:domainId" element={<GovernmentDomainDetailIndex />} />

      <Route path="/resubmit">
        <Route path="profile">
          <Route index element={<RenewProfileIndex />} />
          <Route path=":domainId" element={<RenewProfileIndex />} />
        </Route>
        <Route path="document/:domainId" element={<RenewDocument />} />
        <Route path="summary/:domainId" element={<RenewSummary />} />
      </Route>

      <Route path="purchase/thank" element={<PurchaseThank />} />
      <Route path="renew/thank" element={<RenewThank />} />

      <Route path="/help" element={<Help />} />
      <Route path="/help/download" element={<DownloadDocument />} />
      <Route path="/help/term-condition" element={<TermAndCondition />} />
      <Route path="/help/faq" element={<Faq />} />
      <Route path="/error/bad-request" element={<PageBadRequest />} />
      <Route path="/error/server-error" element={<PageServerError />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  ); */

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<></>}>
                            <Home />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="help/faq"
                    element={
                        <Suspense fallback={<></>}>
                            <FAQsPage />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="help/how-to-register"
                    element={
                        <Suspense fallback={<></>}>
                            <HowToRegisterPage />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="required-documents"
                    element={
                        <Suspense fallback={<></>}>
                            <RequireDocuments />
                        </Suspense>
                    }
                ></Route>
                {/* <Route path="search-domain" element={<SearchDomainPage />}></Route> */}
                {/* <Route path="my-cart" element={<MyCartV2 />}></Route> */}
                {/* <Route path="my-profile" element={<MyProfilePage />} /> */}
                <Route
                    path="help/general-info"
                    element={
                        <Suspense fallback={<></>}>
                            <GeneralInfo />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="about"
                    element={
                        <Suspense fallback={<></>}>
                            <About />
                        </Suspense>
                    }
                ></Route>
                <Route path="search-domain" element={<SearchDomainIndex />} />
                <Route path="whois" element={<WhoIs />} />
                <Route path="whois/search-domain" element={<WhoIsSearchDomain />} />
                <Route path="my-domain" element={<MyDomain />} />
                <Route path="my-cart" element={<MyCart />} />

                <Route path="my-domain-detail/:domainId" element={<DomainDetailIndex />} />
                <Route path="payment/:domainId/:year/:domainName" element={<PaymentIndex />} />
                <Route path="my-profile" element={<UserProfile />} />
                <Route path="update-server-info/:domainId" element={<UpdateServerInfo />} />
                <Route path="purchase">
                    <Route path="profile">
                        <Route index element={<BuyDomainIndex />} />
                        <Route path=":domainId" element={<BuyDomainIndex />} />
                    </Route>
                    <Route path="document/:domainId" element={<DocumentForm />} />
                    <Route path="server-info/:domainId" element={<ServerInfoForm />} />
                    <Route path="summary/:domainId" element={<SummaryForm />} />
                    <Route path="success/:domainId" element={<PurchaseSuccess />} />
                </Route>
                <Route path="resubmit">
                    <Route path="profile">
                        <Route index element={<RenewProfileIndex />} />
                        <Route path=":domainId" element={<RenewProfileIndex />} />
                    </Route>
                    <Route path="document/:domainId" element={<RenewDocument />} />
                    <Route path="summary/:domainId" element={<RenewSummary />} />
                </Route>

                <Route path="purchase/thank" element={<PurchaseThank />} />
                <Route path="renew/thank" element={<RenewThank />} />

                <Route path="/government">
                    <Route path="profile">
                        <Route index element={<GovernmentProfile />} />
                        <Route path=":domainId" element={<GovernmentProfile />} />
                    </Route>
                    <Route path="document/:domainId" element={<GovernmentDocument />} />
                    <Route path="server-info/:domainId" element={<GovernmentServerInfo />} />
                    <Route path="summary/:domainId" element={<GovernmentSummary />} />
                </Route>

                <Route path="/government-resubmit">
                    <Route path="profile">
                        <Route index element={<GovernmentResubmitProfile />} />
                        <Route path=":domainId" element={<GovernmentResubmitProfile />} />
                    </Route>
                    <Route path="document/:domainId" element={<GovernmentResubmitDocument />} />
                    <Route path="summary/:domainId" element={<GovernmentResubmitSummary />} />
                </Route>

                <Route path="/government-domain-detail/:domainId" element={<GovernmentDomainDetailIndex />} />

                <Route path="/help/term-condition" element={<TermAndCondition />} />
                <Route path="/error/bad-request" element={<PageBadRequest />} />
                <Route path="/error/server-error" element={<PageServerError />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="payment/invoice/:invoiceId" element={<Invoice />} />
            <Route
                path="payment/receipt/:receiptId"
                element={
                    <Suspense fallback={<></>}>
                        <Receipt />
                    </Suspense>
                }
            ></Route>
        </Routes>
    );
};
export default Routers;
