import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import DomainService from '../../services/domain/DomainService';
import { HTTP_STATUSES } from '../../constants/constant';
import { WhoIsSearchDomainContent } from './WhoIsSearchDomainContent';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useTranslation } from 'react-i18next';
import SearchComponent from 'v2/core/components/search/search';
import PageTitle from 'v2/core/components/page-title/page-title';

export const WhoIsSearchDomain = () => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [result, setResult] = useState(null);

    const isAuthenticated = useIsAuthenticated();

    let navigate = useNavigate();
    let domainName = searchParams.get("domain");
    let domainType = searchParams.get("type");

    let accessToken = useMsalAccessToken();

    useEffect(() => {
        let body = {
            domain: domainName,
            domainTypeId: domainType
        };

        whoIsSearch(body);

    }, [domainName, domainType])

    const handleWhoIsError = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case HTTP_STATUSES.BAD_REQUEST:
                    navigate('/error/bad-request');
                    break;
                default:
                    navigate('/error/server-error');
            }
        };
    }


    const whoIsSearch = (body) => {
        let domainService = new DomainService();
        if (isAuthenticated && accessToken != null) {
            domainService.whoIs2Search(accessToken, body).then(response => {
                setResult(response);
            }).catch(error => {
                handleWhoIsError(error);
            })
        } else {
            domainService.whoIs2Search(null, body).then(response => {
                setResult(response);
            }).catch(error => {
                handleWhoIsError(error);
            })
        }
    };

    return (
        <Fragment>
            {
                result &&
                <div className="domain-search-page domain-whoIs body-section">
                    <div className="container-1200 container-1200-max">
                        <section className="content-section">
                            <PageTitle title={t("FindOutDomain’sOwnershipText")} />
                            <SearchComponent isWhoIsSearch={true} withSubmitButton fullWidth />
                            <section className="row d-flex align-items-center my-3">
                                <div className="col-auto">
                                    <h4 className="search-term d-inline-block">{domainName}</h4>
                                </div>
                                {
                                    (result && result.whoIsContact) &&
                                    <div className="col dashboard">
                                        <span className="badge badge-danger">{t("DomainRegistered")}</span>
                                    </div>
                                }
                            </section>
                            {
                                domainName &&
                                <WhoIsSearchDomainContent query={searchParams} data={result} />
                            }
                        </section>
                    </div>
                </div>
            }
        </Fragment>
    )
}