import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import PageTitle from 'v2/core/components/page-title/page-title';

export const TermAndCondition = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="home-page">
                <div className="container-1200 content-section">
                    <PageTitle title={t('TermAndCondition')} />                    
                    <div className="faq-container">                        
                        <section className="faq">
                            <p className="mb-2">{t('TermCaption')}</p>
                            <div className="accordion" id="accordionFaq">
                                <div className="faq-item">
                                    <div id="faq1" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition1')}
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq2" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition2')}
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq3" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition3')}
                                        </p>

                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq4" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition4')}
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq5" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition5')}
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq5" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition6')}
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div id="faq5" className="d-flex align-items-center">
                                        <p className="faq-title mb-0">
                                            {t('TermCondition7')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}