import Picture from '../../picture/picture';

import imgPayments from '../../../../assets/images/payments.png';
import imgTRCLogo from '../../../../assets/images/trc-logo.png';

import webpPayments from '../../../../assets/images/payments.webp';
import webpTRCLogo from '../../../../assets/images/trc-logo.webp';

import { ReactComponent as SVGMPTCLogo } from '../../../../assets/svg/mptc-logo.svg';

import { faFacebookF, faInstagram, faTelegramPlane, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCaretUp, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-1200">
                <div className="logos">
                    <a href="https://mptc.gov.kh/" target="_blank" rel="noopener noreferrer">
                        <SVGMPTCLogo/>
                    </a>
                    <a href="https://trc.gov.kh/en/" target="_blank" rel="noopener noreferrer">
                        <Picture imgSrc={imgTRCLogo} webpSrc={webpTRCLogo} />
                    </a>
                </div>
                <div className="flex-row footer-center">
                    <div className="flex-column footer-info">
                        <a
                            className="link"
                            href="https://g.page/CambodiaPostOffice?share"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faLocationDot} /> <span>{t('TRCAddressText')}</span>
                        </a>
                        <a className="link" href="tel:+85523722333" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faPhone} /> <span>6789 ({t('FreeText')}) | 023 722 333</span>
                        </a>
                        <a className="link" href="mailTo:info@domain.gov.kh" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} /> <span>info@domain.gov.kh</span>
                        </a>
                    </div>
                    <div className="flex-column footer-links">
                        <Link to="/search-domain" className="link">
                            <span>{t('RegisterDomain')}</span>
                        </Link>
                        <Link to="/whois" className="link">
                            <span>{t('findoutdomain-sownershiptext')}</span>
                        </Link>
                        <Link to="/help/faq" className="link">
                            <span>{t('questionAnswer')}</span>
                        </Link>
                    </div>
                    <div className="flex-column footer-socials">
                        <h5>{t('ForNewUpdate')}</h5>
                        <div className="socials">
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/www.mptc.gov.kh">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/mptcgovkh">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mptc.gov.kh">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://t.me/domain_kh_bot">
                                <FontAwesomeIcon icon={faTelegramPlane} />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/MPTCMedia">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-1200">
                    <div className="footer-bottom-container flex-row">
                        <div className="flex-column">
                            <p>
                                <span>
                                    &copy; {t('copyright')} 2022 • {t('mptc')}
                                </span>
                                <span>{t('allRightReserved')}</span>
                            </p>
                            {/* <div className="links">
                                <Link to="/help/term-condition" target="_blank" className="link">
                                    <span>{t('TermAndConditionDotText')}</span>
                                </Link>
                                <Link to="#" className="link">
                                    <span>{t('PrivacyPolicy')}</span>
                                </Link>
                            </div> */}
                        </div>
                        <div className="footer-bottom-payments flex-row">
                            <span className="accept">{t('weAccept')} :</span>
                            <Picture imgSrc={imgPayments} webpSrc={webpPayments} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
