import { Link, useLocation } from 'react-router-dom';
import Picture from '../../picture/picture';
import './header.scss';

import imgLogo from '../../../../assets/images/logo.png';
import webpLogo from '../../../../assets/images/logo.webp';
import DesktopNavigation from '../desktop-navigation/desktop-navigation';
import MobileNavigation from '../mobile-navigation/mobile-navigation';

import { useMsal } from '@azure/msal-react';
import { b2cPolicies, loginRequest } from 'authConfig';
import { getCurrentLanguage } from 'helpers/getLanguage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const location = useLocation();
    const { instance } = useMsal();
    const [language, setLanguage] = useState();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(getCurrentLanguage());
    }, [i18n, language]);

    const onSwitchLanguage = () => {
        let language = "km";
        if(getCurrentLanguage() === "km"){
            language = "en";
        }

        setLanguage(language);
        localStorage.setItem("language", JSON.stringify(language));
    };

    const onLoginClick = () => {
        // setLogin(true);
        instance.loginRedirect({
            ...loginRequest,
            extraQueryParameters: {
                ui_locales: getCurrentLanguage()
            }
        });
    };

    const onLogoutClick = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: '/'
        });
    };

    const onResetPasswordClick = () => {
        instance.loginRedirect({
            ...loginRequest,
            authority: b2cPolicies.authorities.forgotPassword.authority,
            extraQueryParameters: {
                ui_locales: getCurrentLanguage()
            }
        });
    };

    return (
        <header className={location.pathname === '/' ? 'header' : 'header header-bg'}>
            <div className="container">
                <div className="flex-row header-container">
                    <div className="flex-column">
                        <Link to="/">
                            <h1>
                                <Picture imgSrc={imgLogo} webpSrc={webpLogo} />
                            </h1>
                        </Link>
                    </div>
                    <MobileNavigation
                        onLoginClick={onLoginClick}
                        onLogoutClick={onLogoutClick}
                        onResetPasswordClick={onResetPasswordClick}
                        onSwitchLanguage={onSwitchLanguage}
                    />
                    <DesktopNavigation
                        onLoginClick={onLoginClick}
                        onLogoutClick={onLogoutClick}
                        onResetPasswordClick={onResetPasswordClick}
                        onSwitchLanguage={onSwitchLanguage}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
