import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Controller, useForm } from 'react-hook-form';
import React, { Fragment, useEffect, useState } from 'react';

import { CustomSpinner } from '../../components/share/Spinner';
import { InteractionType } from '@azure/msal-browser';
import { LookupService } from '../../services/LookupService';
import UserProfileService from '../../services/user/UserProfileService';
import moment from 'moment-timezone';
import { notification } from 'antd';
import { sortCountries } from '../../helpers/lookupHelper';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userProfileValidation } from '../../models/validations/profile/ProfileValidation';
import PageTitle from 'v2/core/components/page-title/page-title';
import { getCurrentLanguage } from 'helpers/getLanguage';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { width } from '@mui/system';

export const UserProfile = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, {
        extraQueryParameters: { ui_locales: getCurrentLanguage() }
    });

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm(userProfileValidation);

    const { t, i18n } = useTranslation();

    //Lookups
    const [genders, setGenders] = useState([]);
    const [countries, setCountries] = useState([]);
    const lookupServices = LookupService();

    //Form Data
    const [customer, setCustomer] = useState();
    const [profileData, setProfileData] = useState();

    const [loading, setLoading] = useState('none');

    let navigate = useNavigate();
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        const requestControlDatas = async () => {
            const genders = await lookupServices.getGender(accessToken);
            setGenders(genders.data);

            const countries = await lookupServices.getCountry(accessToken);
            setCountries(countries.data);

            let userProfileService = new UserProfileService();
            if (accessToken) {
                const profile = await userProfileService.getProfile(accessToken);
                setProfileData(profile.data.data);

                let customerDetail = {
                    nameEn: profile.data.data.name,
                    sexId: 1,
                    countryId: 39,
                    email: profile.data.data.email,
                    userEmail: profile.data.data.email
                };
                customerDetail = { ...customerDetail, ...profile.data.data.customerDetail };
                reset(customerDetail);
            }
        };

        requestControlDatas();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    useEffect(() => {
        //Sort when change language
        i18n.on('languageChanged', function (lng) {
            if (countries) {
                setCountries(sortCountries(countries));
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    const onSubmit = (data) => {
        setLoading('');
        let userProfileService = new UserProfileService();

        data.dateOfBirth = moment(data.dateOfBirth).utc(true);

        userProfileService.saveProfile(accessToken, data).then((res) => {
            if (res.data.status === 'Ok') {
                // console.log(res.data.data);
                setCustomer(res.data.data);
                notification.success({
                    message: t('ProfileUpdatedText')
                });
                navigate('/');
            }
        });
    };

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <CustomSpinner display={loading} loadingText="Updating" />
                <div className="purchase-page profile-page mb-5">
                    <div className="content-section container-1200 container-1200-max">
                        <PageTitle title={t('personalInfoTitle')} />
                        {profileData && !profileData.isProfileCompleted && (
                            <p className="text-danger mb-4">{t('PleaseFillInYourProfileInformationText')}</p>
                        )}
                        <form onSubmit={handleSubmit(onSubmit)} noValidate name="form" className="custom-form">
                            <div className="card dns-card card-border-top">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('nameKh')}
                                                    name="nameKh"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('NameText')}
                                                />
                                                <label htmlFor="inputFullName(kh)" className="float-label">
                                                    {t('NameText')} <span className="text-danger">*</span>
                                                </label>
                                                <div className="text-danger">{t(errors.nameKh?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('nameEn')}
                                                    name="nameEn"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('LatinNameText')}
                                                />
                                                <label htmlFor="inputFullName(en)" className="float-label">
                                                    {t('LatinNameText')} <span className="text-danger">*</span>
                                                </label>
                                                <div className="text-danger">{t(errors.nameEn?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Controller
                                                    name="dateOfBirth"
                                                    render={({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                {...field}
                                                                // required={true}
                                                                label={t('DateofBirthText')}
                                                                onChange={(newValue) => {
                                                                    field.onChange(
                                                                        moment(newValue, 'YYYY-MM-DD').format()
                                                                    );
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        required
                                                                        // value={moment(field.value)}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                    control={control}
                                                />
                                                <div className="text-danger">{t(errors.dateOfBirth?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <select
                                                            {...register('sexId')}
                                                            name="sexId"
                                                            className="form-control"
                                                        >
                                                            {genders &&
                                                                genders.map((gender) => {
                                                                    return (
                                                                        <option key={gender.id} value={gender.id}>
                                                                            {i18n.language === 'en'
                                                                                ? gender.text
                                                                                : gender.textKh}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                        <label htmlFor="sex" className="float-label">
                                                            {t('SexText')} <span className="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <select
                                                            {...register('countryId')}
                                                            name="countryId"
                                                            className="form-control"
                                                        >
                                                            {countries &&
                                                                countries.map((country) => {
                                                                    return (
                                                                        <option key={country.id} value={country.id}>
                                                                            {i18n.language === 'en'
                                                                                ? country.nationality
                                                                                : country.nationalityKh}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                        <label htmlFor="country" className="float-label">
                                                            {t('NationalityText')}{' '}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('idOrPassportNumber')}
                                                    type="text"
                                                    className="form-control"
                                                    name="idOrPassportNumber"
                                                    placeholder={t('IDOrPassportText')}
                                                />
                                                <label htmlFor="idOrPassportNumber" className="float-label">
                                                    {t('IDOrPassportText')} <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">
                                                    {t(errors.idOrPassportNumber?.message)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('jobTitle')}
                                                    type="text"
                                                    className="form-control"
                                                    name="jobTitle"
                                                    id="jobTitle"
                                                    placeholder={t('JobTitleText')}
                                                />
                                                <label htmlFor="jobTitle" className="float-label">
                                                    {t('JobTitleText')} <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">{t(errors.jobTitle?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('companyName')}
                                                    type="text"
                                                    className="form-control"
                                                    name="companyName"
                                                    id="companyName"
                                                    placeholder={t('OrganizationOrCompanyText')}
                                                />
                                                <label htmlFor="companyName" className="float-label">
                                                    {t('OrganizationOrCompanyText')}{' '}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">{t(errors.companyName?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    {...register('phoneNumber')}
                                                    type="tel"
                                                    className="form-control"
                                                    name="phoneNumber"
                                                    id="phoneNumber"
                                                    placeholder={t('PhoneNumberText')}
                                                />
                                                <label htmlFor="phoneNumber" className="float-label">
                                                    {t('PhoneNumberText')} <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">{t(errors.phoneNumber?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    {...register('email')}
                                                    className="form-control"
                                                    name="email"
                                                    placeholder={t('ContactEmailText')}
                                                />
                                                <label htmlFor="email" className="float-label">
                                                    {t('ContactEmailText')} <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">{t(errors.email?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control disabled"
                                                    // value={profileData?.email}
                                                    {...register('userEmail')}
                                                    name="userEmail"
                                                    id="userEmail"
                                                    placeholder={t('EmailText')}
                                                    disabled
                                                    required
                                                />
                                                <label htmlFor="userEmail" className="float-label">
                                                    {t('UserEmailText')} <span className="text-danger"> *</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea
                                                    {...register('address')}
                                                    placeholder={t('AddressText')}
                                                    className="form-control"
                                                    cols="3"
                                                    name="address"
                                                ></textarea>
                                                <label htmlFor="address" className="float-label">
                                                    {t('AddressText')} <span className="text-danger"> *</span>
                                                </label>
                                                <div className="text-danger">{t(errors.address?.message)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary btn-block text-white">
                                                {t('Save')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    );
};
